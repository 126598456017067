<template>
  <v-container>

  </v-container>
</template>

<script>

export default{
  name: 'GraphComponent',
};
</script>
