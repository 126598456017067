<template>
  <v-container>
    <v-row>
      <v-col cols="1"></v-col>
      <v-col cols="10">
        <v-card>
          <v-card-title>Our Mission</v-card-title>
          <v-card-text class="bg-surface-light pt-4">
            The Scam Takedown Task Force is a group of like minded people who have dedicated their skills, resources, and time into identification, recording, and tracking of scam websites from across the internet. We work together to help spread awareness of scams in an effort to help people from becoming victims.
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <!-- <v-col cols="1"></v-col> -->
      <v-col cols="12">
        <v-card variant="text">
          <v-card-title>Our Data</v-card-title>

          <v-card-text>
            <div>Simply by using our browser extension, we collect the URLs of sites that are visited to scan automatically without collecting any personal information.  If sites match our profile of scams, we add them to a list to be reviewed manually by our team of Auditors.  If they agree, then a store is considered a scam and added to our list of tracked scam sites</div>
            <v-row align="center" justify="space-around">
              <v-col cols="12" md="4">
                <v-card variant="tonal">
                  <v-card-title class="font-weight-regular">Scam Sites Tracked</v-card-title>
                  <v-card-text class="text-h4">{{ scamcount.toLocaleString() }}</v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="4">
                <v-card variant="tonal">
                  <v-card-title class="font-weight-regular">Sites Scanned Today</v-card-title>
                  <v-card-text class="text-h4">{{ todayscanned.toLocaleString() }}</v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="4">
                <v-card variant="tonal">
                  <v-card-title class="font-weight-regular">Sites Added Today</v-card-title>
                  <v-card-text class="text-h4">{{ todaycount.toLocaleString() }}</v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>

        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card variant="text">
          <v-card-title>Our Browser Extensions</v-card-title>
          <v-card-subtitle>Download our browser extensions to receive a notification when you browse onto a website that has been reported as a scam</v-card-subtitle>
          <v-card-text>
            <v-row align="center" justify="space-around">
              <v-col cols="12" md="4">
                <v-card link target="_blank" href="https://addons.mozilla.org/en-US/firefox/addon/stdtf-site-scanner/">
                  <v-card-title>Firefox</v-card-title>
                </v-card>
              </v-col>
              <v-col cols="12" md="4">
                <v-card link target="_blank" href="https://chrome.google.com/webstore/detail/scam-takedown-task-force/pgefmbhcbijcdeidlbnokonemgbeogag">
                  <v-card-title>Chrome</v-card-title>
                </v-card>
              </v-col>
              <v-col cols="12" md="4">
                <v-card link target="_blank" href="https://microsoftedge.microsoft.com/addons/detail/scam-takedown-task-force-/hmlcafpldgeflflncbcbpnfjceaaoepj">
                  <v-card-title>Edge</v-card-title>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card variant="text">
          <v-card-title>Join Us</v-card-title>
          <v-card-subtitle>Learn about scams, share with your friends and family to spread awareness, and don't forget to like and subscribe!</v-card-subtitle>
          <v-card-text>
            <v-row align="center" justify="space-around">
              <v-col cols="12" md="4">
                <v-card link target="_blank" href="https://www.youtube.com/c/ScamTakedownTaskForce" variant="flat">
                  <v-card-text>
                    <v-img :src="require('@/assets/YouTube.png')" class="my-3" contain height="64px" />
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="4">
                <v-card link target="_blank" href="https://www.facebook.com/STakedownTF" variant="flat">
                  <v-card-text>
                    <v-img :src="require('@/assets/Facebook.png')" class="my-3" contain height="64px" />
                  </v-card-text>
                </v-card>
              </v-col>
              <!-- <v-col cols="12" md="4">
                <v-card link to="https://microsoftedge.microsoft.com/addons/detail/scam-takedown-task-force-/hmlcafpldgeflflncbcbpnfjceaaoepj">
                  <v-card-title>Edge</v-card-title>
                </v-card>
              </v-col> -->
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>

  <GraphComponent />
</template>

<script>
import { defineComponent } from 'vue';

// Components
import GraphComponent from '../components/GraphComponent.vue';

export default defineComponent({
  name: 'HomeView',

  components: {
    GraphComponent,
  },

  created : function(){
    this.refreshPage();

    setInterval( () => {
      this.refreshPage();
    }, 30000);

    fetch("https://scamtdtf.com:3000/api/recordpagevisit", { method: "POST", headers: { "page" : "Home" } })
  },

  data : function(){
    return{
      todaycount : 0,
      todayscanned : 0,
      scamcount : 0,
    }
  },

  methods:{
    refreshPage(){
        fetch("https://scamtdtf.com:3000/api/getlatesthits/todaycountdata", { method: "POST" })
        .then(res => res.json())
        .then(res => this.todaycount = res[0].count );

        fetch("https://scamtdtf.com:3000/api/getlatesthits/scamcountdata", { method: "POST" })
        .then(res => res.json())
        .then(res => {
          this.scamcount = res[0].count;
        });

        fetch("https://scamtdtf.com:3000/api/gettotalscannedtoday", { method: "POST" })
        .then(res => res.json())
        .then(res => {
          this.todayscanned = res[0].count;
        });

        fetch("https://scamtdtf.com:3000/api/getextensions", { method: "POST" })
        .then(res => res.json())
        .then(res => {
          this.extensions = res;
        });
    }
  }
});
</script>
