<template>
  <v-app>
    <v-app-bar>
      <v-container fluid>
        <v-row justify="center" align="center">
          <v-col class="hidden-sm-and-down" md="1"><v-img :src="require('@/assets/logo.png')" class="my-3" contain height="54px" /></v-col>
          <v-col class="hidden-sm-and-down" md="5">
            <!-- <v-text-field v-model="search" :style="{ 'margin-top' : '20px', 'margin-left' : '20px' }"  :append-icon="search ? 'mdi-magnify' : ''" @click:append="searchWebsite" @keyup.enter="searchWebsite" clearable single-line variant="solo" density="compact" label="Search website" type="text" /> -->
          </v-col>
          <v-col class="hidden-sm-and-down" md="2" />
          <v-col cols="12" md="4" align="right">
            <v-btn link to="/">Home</v-btn>
            <v-btn link to="/support">Support Us</v-btn>
            <v-btn link to="/updates">News</v-btn>
          </v-col>
        </v-row>
      </v-container>
      <!-- <v-btn link to="/report">Report a Scam</v-btn> -->
    </v-app-bar>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    search : ""
  }),

  methods:{
    searchWebsite(){
      this.$router.push( { path : '/scamsearch', query : { search : this.search }} )
    }
  }
}
</script>